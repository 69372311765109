export const Types = {
  SET_USER_TOKEN: "SET_USER_TOKEN",
  SET_DATE_START: "SET_DATE_START",
  SET_DATE_END: "SET_DATE_END",
  RM_USER_TOKEN: "RM_USER_TOKEN",
  SHOW_MODALBOX: "SHOW_MODALBOX",
  SET_CURRENT_COODRDS: "SET_CURRENT_COODRDS",
  SET_CURRENT_ADDRESS: "SET_CURRENT_ADDRESS",
  SET_LIST_PICTURES: "SET_LIST_PICTURES",
  RM_LIST_PICTURES: "RM_LIST_PICTURES",
  SET_OFFERS: "SET_OFFERS",
  SET_QUERY_SEARCH: "SET_QUERY_SEARCH",
  TOGGLE_FILTER_MODAL: "TOGGLE_FILTER_MODAL",
  SET_PRICE_RANGE: "SET_PRICE_RANGE",
  SET_PRICE_TYPE: "SET_PRICE_TYPE",
  SET_VEHICLES_TYPE: "SET_VEHICLES_TYPE",
  REST_ALL_FILTERS: "REST_ALL_FILTERS",
  SET_LOADING: "SET_LOADING",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SET_SELECTED_OFFER: "SET_SELECTED_OFFER",
  SHOW_COMPLETE_PROFILE: "SHOW_COMPLETE_PROFILE",
  SET_COMPLETE_PROFILE: "SET_COMPLETE_PROFILE",
  SHOW_MODAL_OFFER: "SHOW_MODAL_OFFER",
  SET_LISTBY: "SET_LISTBY",
  SET_SEARCH_POSITION: "SET_SEARCH_POSITION",
  OPEN_MODAL_TELEPHONE: "OPEN_MODAL_TELEPHONE",
}
